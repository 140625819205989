import { styled } from '@styledComponents';
import { themeMediaQuery } from '@utils/styledTheme.util';
import React, { PropsWithChildren, PropsWithRef } from 'react';
import { LazyLoadDefaultProps } from '@utils/lazyLoadDefaultProps';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { useBackgroundCloudAssetUrlProps } from './useBackgroundCloudAssetUrlProps';
import { BackgroundCloudImageProps, LazyImageBackgroundProps } from './BackgroundCloudImage.types';
import { useScrollControllerContext } from '@context/ScrollControllerProvider/ScrollControllerProvider';

const LazyImageBackground = styled.div.attrs({ shouldForwardProp })<LazyImageBackgroundProps>`
  ${({ gradient, backgroundImage }) =>
    gradient
      ? {
          background: `url(${backgroundImage}), ${gradient}`,
        }
      : {
          backgroundImage: `url(${backgroundImage})`,
        }};
  ${themeMediaQuery('below_sm')} {
    ${({ gradient, gradientMobile, backgroundImage, backgroundImageMobile }) =>
      gradient || gradientMobile
        ? {
            background: `url(${backgroundImageMobile ?? backgroundImage}), ${gradientMobile ?? gradient}`,
          }
        : {
            backgroundImage: `url(${backgroundImageMobile ?? backgroundImage})`,
          }};
  }
`;

const { isImagesEagerByDefault, defaultLazyLoadThreshold } = LazyLoadDefaultProps;

const BackgroundBaseImage = React.forwardRef<HTMLDivElement, BackgroundCloudImageProps>(
  ({ id, src, srcMobile, width, height, className, style, isEagerLoading, children, threshold, afterLoad, ...restProps }, ref) => {
    const { scrollPosition } = useScrollControllerContext() || {};
    return (
      <LazyLoadComponent
        visibleByDefault={isEagerLoading ?? isImagesEagerByDefault}
        threshold={threshold ?? defaultLazyLoadThreshold}
        scrollPosition={scrollPosition}
        afterLoad={afterLoad}
      >
        <LazyImageBackground
          {...{ width, height, className, backgroundImage: src, backgroundImageMobile: srcMobile, style }}
          {...restProps}
          ref={ref}
        >
          {children}
        </LazyImageBackground>
      </LazyLoadComponent>
    );
  }
);

const withBackgroundCloudAssetUrl = (Component: React.FunctionComponent<PropsWithChildren<PropsWithRef<BackgroundCloudImageProps>>>) => {
  const WrappedComponent = React.forwardRef<HTMLDivElement, BackgroundCloudImageProps>((props, ref) => {
    const [gatsbyLinkProps] = useBackgroundCloudAssetUrlProps(props);
    return (
      <Component {...gatsbyLinkProps} ref={ref}>
        {props.children}
      </Component>
    );
  });
  WrappedComponent.displayName = `WithBackgroundCloudAssetUrl(${Component.displayName || Component.name})`;
  return WrappedComponent;
};

const BackgroundCloudImage = styled(withBackgroundCloudAssetUrl(BackgroundBaseImage))<
  PropsWithChildren<PropsWithRef<BackgroundCloudImageProps>>
>``;

BackgroundCloudImage.defaultProps = {};

BackgroundCloudImage.displayName = 'BackgroundCloudImage';

export { BackgroundCloudImage };
