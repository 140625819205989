import { PropsWithChildren, useMemo } from 'react';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';
import { BackgroundCloudImageProps } from './BackgroundCloudImage.types';
import { defaultBase64Placeholder } from '@components/CloudImage';

export function useBackgroundCloudAssetUrlProps<T extends BackgroundCloudImageProps>(hocProps: T): [PropsWithChildren<T>] {
  const props = useMemo(() => {
    const {
      src,
      srcMobile,
      resizeWidth,
      resizeHeight,
      mobileResizeWidth,
      mobileResizeHeight,
      isResizeDisabled,
      optimization,
      ...restProps
    } = hocProps;
    let props = {} as PropsWithChildren<T>;
    props.src =
      src === ''
        ? defaultBase64Placeholder
        : cloudAssetUrl(src, { resizeWidth, resizeHeight, mobileResizeWidth, mobileResizeHeight, isResizeDisabled, optimization });
    if (srcMobile) {
      props.srcMobile = cloudAssetUrl(srcMobile, {
        resizeWidth,
        resizeHeight,
        mobileResizeWidth,
        mobileResizeHeight,
        isResizeDisabled,
        optimization,
      });
    }
    return { ...restProps, ...props };
  }, [hocProps]);
  return [props];
}
