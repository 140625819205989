import { styled } from '@styledComponents';
import { themeSpace, themeColor } from '@utils/styledTheme.util';
import { CTALink } from '@components/CTALink';
import { Text } from '@components/typography';
import { Box } from '@components/Box';
import { themeMediaQuery } from '@utils/styledTheme.util';
import { BackgroundCloudImage } from '@components/BackgroundCloudImage';

export const ContainerBox = styled(Box)`
  display: none;
  ${themeMediaQuery('below_sm')} {
    display: block;
  }
`;

export const FeatureLink = styled(CTALink)`
  width: 100%;
  margin-bottom: ${themeSpace(6)};
  > span {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: ${themeSpace(5)};
    white-space: normal;
  }
`;

export const FeaturePhoto = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 60vw;
  max-height: 60vw;
  margin-bottom: ${themeSpace(4)};
  .featureBackgroundImg {
    width: 100%;
    height: 100%;
  }
  .featureBaseImg {
    width: 100%;
    position: absolute;
    left: 0;
  }
  .featureUiImg {
    width: 100%;
    position: absolute;
    left: 0;
  }
`;

export const FeatureTitle = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: normal;
  div:first-child {
    height: 18px;
  }
  svg {
    margin-left: ${themeSpace(4)};
    height: 18px;
  }
`;

export const FeatureImage = styled(BackgroundCloudImage)`
  background-color: ${themeColor('warmGray1')};
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
`;
